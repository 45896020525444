import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// components
import { MotionViewport, varFade } from 'src/components/animate';

// ----------------------------------------------------------------------

const CARDS = [
  {
    icon: ' /assets/icons/home/ic_make_brand.svg',
    title: 'Platform for Supply Chain Facilitation',
    description:
      'Cartify operates as a digital platform where restaurant operators can connect with registered suppliers for purchasing food and beverages. This role enhances the efficiency of the supply chain process, reducing the time and effort restaurant operators spend sourcing and managing their inventory, while providing suppliers with a reliable channel to reach a wider audience.',
  },
  {
    icon: ' /assets/icons/home/ic_design.svg',
    title: 'Mobile Application Convenience',
    description:
      'Cartify services are offered through a user-friendly mobile application, making it convenient for restaurant operators to browse, compare, and order supplies anytime, anywhere. This enables quicker decision-making, real-time tracking of orders, and provides an overall seamless shopping experience for restaurant operators.',
  },
  {
    icon: ' /assets/icons/home/ic_development.svg',
    title: 'Quality Assurance and Variety',
    description:
      'Through partnerships with a broad range of registered suppliers, Cartify ensures restaurant operators have access to a diverse selection of quality food and beverage products. By vetting suppliers, Cartify not only ensures the quality of products on its platform but also supports restaurants in maintaining their own standards of service and cuisine.',
  },
];

// ----------------------------------------------------------------------

export default function HomeMinimal() {
  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 10, md: 15 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          textAlign: 'center',
          mb: { xs: 5, md: 10 },
        }}
      >
        <m.div variants={varFade().inDown}>
          <Typography variant="h2">What is Cartify?</Typography>
        </m.div>
      </Stack>

      <Box
        gap={{ xs: 3, lg: 10 }}
        display="grid"
        alignItems="center"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {CARDS.map((card, index) => (
          <m.div variants={varFade().inUp} key={card.title}>
            <Card
              sx={{
                textAlign: 'center',
                boxShadow: { md: 'none' },
                bgcolor: 'background.default',
                p: (theme) => theme.spacing(10, 5),
                ...(index === 1 && {
                  boxShadow: (theme) => ({
                    md: `-40px 40px 80px ${
                      theme.palette.mode === 'light'
                        ? alpha(theme.palette.grey[500], 0.16)
                        : alpha(theme.palette.common.black, 0.4)
                    }`,
                  }),
                }),
              }}
            >
              <Box
                component="img"
                src={card.icon}
                alt={card.title}
                sx={{ mx: 'auto', width: 48, height: 48 }}
              />

              <Typography variant="h5" sx={{ mt: 8, mb: 2 }}>
                {card.title}
              </Typography>

              <Typography sx={{ color: 'text.secondary' }}>{card.description}</Typography>
            </Card>
          </m.div>
        ))}
      </Box>
    </Container>
  );
}
