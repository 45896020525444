import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TermsSection = ({ title, description }) => (
  <Box>
    <Typography
      variant="h6"
      gutterBottom
      sx={{
        fontWeight: 'bold',
      }}
    >
      {title}
    </Typography>
    {description}
  </Box>
);

TermsSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
};

const TermsContent = () => {
  const termsData = [
    {
      title: 'INTRODUCTION',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              The following terms and conditions apply to the legal agreement formed between Cartify
              (“Provider”) and the customer executing a Customer Order Form that is accompanied by
              or references this document (“Customer”).
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: '1. DEFINITIONS',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              As used in this Terms and Conditions Agreement:
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Agreement” means the SaaS agreement between Customer and Provider consisting of
              these Terms and Conditions, any Order Form signed by Customer and Provider that
              accompanies or references this document, and any Statements of Work entered into by
              Customer and Provider hereunder.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Applicable Laws” means all legislation, statutes, regulations, ordinances, rules,
              judgments, orders, decrees, rulings, and other requirements enacted, promulgated, or
              imposed by any governmental authority or judicial or regulatory body (including any
              self-regulatory body) at any level (e.g., municipal, county, provincial, state or
              national) that are applicable to or enforceable against a party or its personnel in
              relation to their activities under or pursuant to this Agreement.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Authorized Customer Entities” means specific Customer-affiliated entities named in
              a Customer Order Form who are authorized to access and use the Services during the
              Subscription Term.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Authorized User(s)” means end users of Customer and Authorized Customer Entities
              who otherwise receive a user ID or other access credentials from Provider or Customer
              authorizing them to access and use the SaaS. The Service is available only to legal
              entities under applicable law and persons who are at least eighteen (18) years old and
              are otherwise capable of forming legally binding contracts.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Authorized Purpose(s)” means those purposes set forth in a Customer Order Form. If
              no Authorized Purpose is stated, the Authorized Purpose shall be limited to use of the
              SaaS in Customer’s and Authorized Customer Entities’ internal business operations.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Confidential Information” means all non-public written or oral information,
              disclosed by either Party to the other, related to the business or operations of
              either Party or a third party that has been identified as confidential or that by the
              nature of the information or circumstances surrounding its disclosure ought reasonably
              to be understood as being confidential.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Content” means any data, media, information and/or other type or form of content
              displayed, distributed or otherwise made available to a Party through or in connection
              with the SaaS or other Services, including User Content and Provider Content.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Customer Data” means any data owned by Customer or an Authorized Customer Entity
              that is submitted to the Services for processing transmission, and/or storage.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Customer Order Form” means an agreement form issued by Provider or any of its
              appointed entities and executed by Customer and Provider (or any of its appointed
              entities) setting forth the necessary information relating to the SaaS and/or other
              Services to be provided to Customer under this Agreement and the fees payable to
              Provider.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Data Privacy and Security Laws” means all applicable and local laws, statutes,
              ordinances, regulations, rules, executive orders, of or by any government entity, or
              any authority, department or agency thereof governing the privacy, data protection and
              security of personally identifiable information and security breach notification
              relating to personally identifiable information, and any other laws in force in any
              jurisdiction (regulatory or otherwise) in which the SaaS is being utilized, as may be
              amended from time to time.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Including” (and its derivative forms, whether or not capitalized) means including
              without limitation.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Intellectual Property Rights” means the legal rights held by the owner of a
              copyright, patent, trademark, or trade secret, including (i) the rights to copy,
              publicly perform, publicly display, distribute, adapt, translate, modify and create
              derivative works of copyrighted subject matter; (ii) the rights to exclude others from
              using, making, having made, selling, offering to sell, and importing patented subject
              matter and to practice patented methods, (iii) the rights to use and display any marks
              in association with businesses, products or services as an indication of ownership,
              origin, affiliation, or sponsorship; and (iv) the rights to apply for any of the
              foregoing rights, and all rights in those applications. Intellectual Property Rights
              also include any and all rights associated with particular information that are
              granted by law and that give the owner, independent of contract, exclusive authority
              to control use or disclosure of the information, including privacy rights and any
              rights in databases recognized by applicable law.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Losses” means, in connection with a Claim that is subject to defense and
              indemnification by a Party under this Agreement, all reasonable attorneys’ fees,
              reasonable costs of investigation, discovery, litigation and settlement, and any
              resulting liabilities, damages, settlements, judgments and awards, including
              associated taxes, interest and penalties, excluding any consequential and indirect
              damages.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Mobile App” refers, if and when applicable, to proprietary client software, in
              object code form, that is made available by Provider for installation on mobile
              devices to allow interaction and use with the SaaS.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Professional Services” means any professional services performed or contracted to
              be performed by Provider pursuant to a Statement of Work entered into by the Parties
              under this Agreement.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Provider Content” means Content owned, originated or controlled by Provider that is
              made accessible to Customer and Authorized Customer Entities via the SaaS or other
              Services.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “SaaS” means Provider’s proprietary software-as-a-service platform and related
              services made available for use by Authorized Users under this Agreement, as
              identified on the applicable Customer Order Form, including its technology components,
              such as Provider’s Web Site, [applicable Mobile App(s),] and related documentation.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Services” means, collectively, the SaaS, the Support Services, and any Professional
              Services performed or provided by Provider pursuant to this Agreement.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Subscription Fees” means the non-recurring and recurring fees payable by Customer
              to Provider for the SaaS and associated Support Services, as set forth in the relevant
              Customer Order Form, which shall be payable in accordance with the payment terms set
              forth in the Customer Order Form. Unless and except as otherwise expressly stated in
              this Agreement, the Subscription Fees are non-cancellable and non-refundable.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Subscription Term” means the period during which the Customer and the Customer’s
              Authorized Users are permitted to access and use the SaaS, as set forth in the
              applicable Customer Order Form.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Support Services” has the meaning given in Section 3.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “Update(s)” means any improvement, enhancement, modification and/or changes to the
              SaaS offered or provided by Provider to its subscribers at no charge.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “User Content” means any Content submitted, posted or displayed by Authorized Users
              of the SaaS [or the Mobile App].
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              - “User Data” means any data or information (other than User Content) received or
              collected by Provider concerning Authorized Users of the SaaS [or the Mobile App],
              including data provided by Authorized Users to register to use the SaaS [or a Mobile
              App].
            </Typography>
          </Box>
        </Box>
      ),
    },

    {
      title: 'ACCESS TO AND USE OF THE SAAS.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              asd
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'DEFINITIONS',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              a- Limited-Purpose Access Grant. Subject to Customer’s and its Authorized Users’
              continuing compliance with this Agreement and payment of the applicable fees, Provider
              hereby grants to Customer a limited, personal, non-exclusive, non-transferable right
              for Authorized Users of Customer and any other Authorized Customer Entities to access
              the features and functions of the SaaS during the Subscription Term, solely through
              Provider’s Mobile App and solely for the Authorized Purpose(s). This access grant may
              not be sublicensed, in whole or in part. The scope of Customer’s use of the SaaS is
              subject to the terms and conditions of this Agreement, including any usage or other
              parameters or limitations set forth in the applicable Customer Order Form.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              b- Access Protocols. On or as soon as reasonably practicable after the execution of
              this Agreement, Provider shall provide to Customer the necessary access credentials
              and protocols to allow Authorized Users to access the SaaS (the “Access Protocols”).
              The Parties further agree that prior to installing the Mobile App, any Authorized User
              would have already accepted the Terms & Conditions through the contract signed between
              the Customer and Provider or any of its appointed entities. Customer acknowledges and
              agrees that, as between Customer and Provider, Customer shall be responsible for all
              acts and omissions of Authorized Users, including any act or omission by an Authorized
              User, which, if undertaken by Customer, would constitute a breach of this Agreement
              and any act by a person (whether or not an Authorized User) using Customer’s Access
              Protocols. Customer shall undertake reasonable efforts to make all Authorized Users
              aware of the provisions of this Agreement that are applicable their use of the SaaS
              and shall cause them to comply with such provisions.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              c- Account Administration. Customer shall designate at least one Authorized User to
              act as an administrator who will act as Customer’s principal point of contract with
              Provider for purposes of this Agreement.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              d- User Content. The SaaS may enable Customer’s Authorized Users to search for, find,
              store, manage and use User Content of interest that is provided or made accessible
              through the SaaS. Customer acknowledges that Provider does not endorse, support,
              represent or guarantee the completeness, truthfulness, accuracy, reliability or other
              attributes of any User Content, nor does Provider review or attempt to verify the
              accuracy or currency of any User Content. As between Customer and Provider, Customer
              is solely responsible for (i) determining the suitability of any User Content for its
              intended use by Customer and its Affiliates, and (ii) as necessary for its intended
              use, verifying the authenticity, integrity, and accuracy of the User Content prior to
              using it. Provider has no obligation to preview, verify, flag, modify, filter or
              remove any User Content. Customer may remove any User Content at any time in its sole
              discretion. In the event Provider reasonably believes any User Content to be harmful,
              inaccurate, unlawful, or otherwise objectionable, it will promptly notify Customer in
              writing and Customer will remove or replace the objected-to User Content. If Customer
              fails to replace or remove objected-to content within ten (10) days following receipt
              of written notice by Provider, Provider may remove or disable access to the
              objected-to content in its sole discretion, but is not responsible for any failures or
              delays in removing or disabling access to any User Content unless otherwise provided
              herein, including User Content that may be considered harmful, inaccurate, unlawful or
              otherwise objectionable.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              e- Compliance. (a) Customer’s and its Authorized Users’ access to and use of the SaaS
              is subject to their continuing compliance with all of the following: (a) the terms and
              conditions set forth in this Agreement; (b) Provider’s online Terms of Use available
              on Provider’s Mobile App; (c) third party service terms and conditions governing any
              Content accessed through the SaaS that is published or distributed by a third-party
              web site, and (d) Applicable Laws, including Data Privacy and Security Laws. In
              addition to complying with applicable Data Privacy and Security Laws, Provider will
              employ commercially reasonable security and access controls designed to protect the
              types of data collected and stored by the Services, including personally identifiable
              information. Provider does not assert ownership over any of the User Content. Rather,
              subject to the rights granted to the Provider and the Authorized Users in this
              Agreement, Customer retains full ownership of all of its content and any intellectual
              property rights or other proprietary rights thereto. Customer understands and agrees
              that it is solely responsible for all of User Content. By uploading User Content on
              the Services, Customer grants Provider a non- exclusive, transferable, fully paid,
              worldwide license to use, copy, reproduce, process, adapt, publish, transmit, host and
              display User Content for the purpose of (i) providing Customer and other users the
              Services and associated support; and (ii) analyzing and improving the operation of the
              Services. Notwithstanding anything to the contrary herein, Customer agree that
              Provider may obtain and aggregate technical and other data about Customer’s use of the
              Services that is non-personally identifiable with respect to Customer (“Aggregated
              Anonymous Data”), and Provider may use the Aggregated Anonymous Data to improve,
              support and operate the Services and otherwise for any business purpose during and
              after the term of this Agreement. For clarity, this does not give Provider the right
              to identify Customer as the source of any Aggregated Anonymous Data. Customer
              authorizes Provider to store copies of any or all of User Content as Provider deems
              necessary in order to facilitate the operation of the Services. Customer represents
              and warrants that it has all rights, consents and/or permissions necessary to grant
              the licenses in the previous paragraph, including under any and all copyright,
              trademark, and other intellectual property rights, as well as any moral rights, rights
              of privacy, rights of publicity and similar rights of any type in or to User Content.
              Customer may not upload to or otherwise make available on the Services any content for
              which Customer do not have all necessary rights, licenses, consents or permissions
              needed to so make available such content on the Services. If Customer elects to
              utilize any third party application in connection with Customer use of the Services,
              by doing so Customer is consenting to its content being shared with such third party
              application. To understand how such third party application provider utilizes User
              content and other information, Customer should review their privacy policy.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              f- Restrictions. Customer agrees not to act outside the scope of the rights that are
              expressly granted by Provider in this Agreement. Further, Customer will not (i) use
              the SaaS in any manner that is inconsistent with this Agreement; (ii) access or use
              the SaaS or in order to develop or support, or assist another party in developing or
              supporting, any products or services competitive with the SaaS; (iii) decompile,
              reverse engineer (unless required by law for interoperability), or use any other
              method in an attempt to view or recreate any of the source code of the SaaS or extract
              any trade secrets from it; (iv) use the SaaS to operate the business of a third party
              or to process data or content provided by a third party for the operation of a third
              party’s business, or otherwise use the SaaS on a third party’s behalf, or to act as a
              service bureau or provider of application services to any third party; (v) knowingly
              or intentionally re-use, disseminate, copy, or otherwise use the SaaS or associated
              Content in a way that infringes, misappropriates, or violates any trademark,
              copyright, patent, trade secret, publicity, privacy or other right of Provider or any
              third party; or (vi) sell, lend, lease, assign, transfer, pledge, permit a lien upon,
              or sublicense any of the rights granted by this Agreement with respect to the SaaS.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              g- No Interference with Service Operations. Customer and its Authorized Users will not
              take any action designed or intended to: (a) interfere with the proper working of the
              SaaS; (b) circumvent, disable, or interfere with security-related features of the SaaS
              or features that prevent or restrict use, access to, or copying the SaaS or any
              Content or other data, or that enforce limitations on use of the SaaS or Content; or
              (c) impose (or which may impose, in Provider’s sole discretion) an unreasonable or
              disproportionately large load on the SaaS infrastructure. The Services are not
              currently intended to be used by any government or public entities or any individuals
              in their capacity as employees or contractors of a government or public entity. If
              Customer is a government user or otherwise accessing or using the Services in its
              capacity as an employee of a government or public entity, please contact Provider at
              support@foodoperations.exchange
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'SUPPORT SERVICES; PROFESSIONAL SERVICES',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              a- Technical Support. At no additional charge and during Provider’s normal business
              hours (which are 9:00 a.m. to 5:00 p.m. Local Time, Monday through Friday, excluding
              Provider-designated holidays unless otherwise specified in the applicable Customer
              Order Form), Provider will provide reasonable technical support and assistance for
              Authorized User requests by telephone +961(3)429911 or sent via email to
              support@cartify.org. Provider may also offer upgraded support services for an
              additional fee.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              b- Updates. Customer will be given access to Updates of the SaaS that Provider
              implements during the Subscription Term. Customer acknowledges, however, that Provider
              may in the future offer optional value-added functions, features, or other
              capabilities for a separate fee.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              c- Scheduled Maintenance. Provider reserves the right to take down applicable servers
              hosting the SaaS to conduct scheduled and emergency maintenance. Provider will use
              commercially reasonable efforts to perform scheduled maintenance outside regular
              business hours and will provide at least 24 hours’ advance notice for nonemergency
              maintenance. Provider will not be responsible for any damages or costs incurred by
              Customer due to unavailability of the SaaS during scheduled or emergency maintenance.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              d- Professional Services. If Provider has agreed to perform Professional Services for
              Customer or an Authorized Customer Entity under this Agreement, the Parties shall
              prepare and sign a Statement of Work describing the Professional Services to be
              performed and setting forth any other pertinent details, including the locations at
              which the Professional Services will be performed, the planned schedule of
              performance, the deliverables (if any) to be produced by Provider and delivered to
              Customer, the amount and manner of payment of Provider’s fees for the Professional
              Services, and any associated responsibilities of Customer or Authorized Customer
              Entities relating to the Professional Services. Customer’s obligation to pay the
              Subscription Fees set forth in an Order Form is not dependent on Provider’s
              performance of any Professional Services pursuant to an SOW unless otherwise agreed by
              the Parties.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              e- Provider Marketplace and Third Party Links. Some parts of the Services are
              supported by sponsored links from advertisers and display offers that may be custom
              matched to Customer based on information stored in the Services or queries made
              through the Services or other information. In connection with Provider, the Services
              will provide links or send emails to other web sites belonging to advertisers and
              other third parties. Provider does not endorse, warrant, guarantee, or make any
              representation regarding the products or services available through the Provider.
              Offers (or any other third party products or services advertised on or linked from
              Provider site), whether or not sponsored. In addition, Provider is not an agent or
              broker or otherwise responsible for the activities or policies of those web sites.
              Provider does not guarantee that the terms of sale or offer posted on the Services by
              or for any particular advertiser or other third party are actually the terms that may
              be offered to you if you pursue the offer or that they are the best terms or price
              available in the market. Provider does not share Customer information (product
              catalog, prices, etc.) with other customer on Provider’s platform. Customer’s product
              lists and prices are only visible to Restaurants who are potential buyers of
              Customer’s products. If Customer becomes aware of any unauthorized use of its
              registration information, Customer agrees to notify the Provider immediately.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              f- Fees. Provider’s fees for the Services are as set forth in the applicable Order
              Form and shall be payable as stated in the Order Form. If any payment is not made in
              full when due, the Customer will be subject to a late payment and a suspension of
              their account will be effective immediately. Provider may choose to offer additional
              value-added Services in addition to the base Service for a fee. If Provider does so,
              the Mobile App will identify the chargeable Services and state the associated fees,
              terms and methods of payment. Quoted fees for chargeable additional Services are
              exclusive of applicable taxes, which will be separately itemized on Provider
              statement. Fees for chargeable additional Services are not cancellable and
              nonrefundable.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'ALLOCATIONS OF RISK.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              a- REPRESENTATIONS AND WARRANTIES. (a) Each Party represents to the other (i) that the
              execution and performance of its obligations under this Agreement will not conflict
              with or violate any provision of Applicable Law or any other agreement or order by
              which the representing Party is bound; and (ii) that this Agreement, when subscribing
              to the Provider application and accepting the Terms and Conditions, will constitute a
              valid and binding obligation of such Party and will be enforceable against such Party
              in accordance with its terms. (b) Provider warrants that any Services performed by
              Provider under this Agreement will be performed in a good and workmanlike manner in
              accordance with prevailing industry standards.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              b- DISCLAIMERS. (a) CUSTOMER REPRESENTS THAT IT IS ENTERING INTO THIS AGREEMENT
              WITHOUT RELYING UPON ANY PROVIDER REPRESENTATION OR WARRANTY NOT EXPRESSLY STATED IN
              THIS AGREEMENT. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, PROVIDER DISCLAIMS
              ANY AND ALL PROMISES, REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY,
              INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, DATA ACCURACY, SYSTEM INTEGRATION, SYSTEM RELIABILITY, TITLE,
              NON-INFRINGEMENT, NON-INTERFERENCE AND/OR QUIET ENJOYMENT, AND ALL WARRANTIES THAT MAY
              OTHERWISE BE IMPLIED. NO WARRANTIES ARE MADE ON THE BASIS OF TRADE USAGE, COURSE OF
              DEALING, OR COURSE OF PERFORMANCE.
              <br />
              <br />
              (b) CUSTOMER ASSUMES COMPLETE RESPONSIBILITY, WITHOUT ANY RECOURSE AGAINST PROVIDER,
              FOR THE SELECTION OF THE SAAS TO ACHIEVE CUSTOMER’S INTENDED RESULTS AND FOR ITS USE
              OF THE RESULTS OBTAINED FROM THE SAAS IN CUSTOMER’S BUSINESS. CUSTOMER ACKNOWLEDGES
              THAT IT IS SOLELY RESPONSIBLE FOR THE RESULTS OBTAINED FROM USE OF THE SAAS, INCLUDING
              THE COMPLETENESS, ACCURACY, AND CONTENT OF SUCH RESULTS. PROVIDER DOES NOT WARRANT
              THAT THE SAAS WILL MEET CUSTOMER’S REQUIREMENTS, THAT THE OPERATION OF THE SAAS WILL
              BE UNINTERRUPTED OR ERROR-FREE, OR THAT ALL ERRORS WILL BE CORRECTED.
              <br />
              <br />
              (c) THE SAAS IS NOT DESIGNED OR PERMITTED TO BE USED IN OR FOR HIGH-RISK OR HAZARDOUS
              ENVIRONMENTS REQUIRING FAILSAFE PERFORMANCE, INCLUDING OPERATION OF AIRCRAFT
              NAVIGATION, COMMUNICATION SYSTEMS, AIR TRAFFIC CONTROL, WEAPONS SYSTEMS, DIRECT
              LIFE-SUPPORT MACHINES, OR ANY OTHER APPLICATION IN WHICH THE FAILURE OF THE SAAS COULD
              LEAD DIRECTLY TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR PROPERTY DAMAGE
              (COLLECTIVELY, HIGH RISK ACTIVITIES). PROVIDER EXPRESSLY DISCLAIMS ANY EXPRESS OR
              IMPLIED WARRANTY OF FITNESS OF THE SAAS FOR HIGH RISK ACTIVITIES.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              c- Indemnification of Customer by Provider. Provider agrees to defend, indemnify, and
              hold harmless Customer from and against all third-party claims and actions
              (collectively, “Claims” and individually, a “Claim”), that may, at any time, arise out
              of or relate to (a) a Claim that the SaaS or any Provider Content (excluding, however,
              User Content) provided by Provider hereunder or Customer’s use of same in accordance
              with the terms hereof infringes any third party’s Intellectual Property Rights; or (b)
              a Claim arising with respect to Provider’s posting or displaying Provider Content on
              Provider’s Mobile App; and, in each case, associated direct Losses.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              d- Indemnification of Provider by Customer. Customer agrees to defend, indemnify and
              hold harmless Provider and its Affiliates from and against all Claims, that may, at
              any time, arise out of or relate to: (a) use of the SaaS or any Content by or on
              behalf of Customer or an Authorized Customer Entity other than in accordance with this
              Agreement; (b) the posting, display, distribution, broadcast or other use of User
              Content by or on behalf of Customer or an Authorized Customer Entity, including Claims
              that any such use infringes or otherwise violates the rights of any third party,
              including Intellectual Property Rights, privacy, publicity or other personal or
              proprietary rights, or that the User Content posted, displayed, distributed, broadcast
              or otherwise published contains libelous, defamatory or otherwise injurious or
              unlawful material; (c) any Claim arising with respect to the products of the Customer
              offered and displayed on the Provider’s application platform ; and, in each case,
              associated direct Losses.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              e- Indemnification Procedures. If any third party makes a Claim covered by Section
              4(c) or Section 4(d) against an indemnified Party (the “Covered Party”) with respect
              to which the Covered Party intends to seek indemnification under this Agreement, the
              Covered Party shall give prompt written notice of the Claim to the indemnifying Party,
              including a brief description of the amount and basis for the claim, if known. Upon
              receiving such notice, the indemnifying Party shall be obligated to defend the Covered
              Party (and its indemnitees) against the Claim, and shall be entitled to assume control
              of the defense and settlement of the Claim. The Covered Party may participate in the
              defense and settlement of the Claim at its own expense, using its own counsel, but
              without any right of control. The indemnifying Party shall keep the Covered Party
              reasonably apprised as to the status of the Claim. Neither the indemnifying party nor
              any Covered Party shall be liable for any settlement of a Claim made without its
              consent. Notwithstanding the foregoing, the Covered Party shall retain responsibility
              for all aspects of the Claim that are not subject to indemnification by the
              indemnifying Party hereunder.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              f- Limitation of Liability. EXCEPT AS EXPRESSLY PROVIDED IN THIS SECTION 4(f), NEITHER
              PARTY SHALL HAVE ANY LIABILITY UNDER OR IN CONNECTION WITH THIS AGREEMENT FOR ANY
              INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, NOR ANY
              LIABILITY FOR LOST PROFITS, LOSS OF DATA, LOSS OF BUSINESS OPPORTUNITY, OR BUSINESS
              INTERRUPTION, REGARDLESS OF THE THEORY OF LIABILITY (INCLUDING THEORIES OF CONTRACTUAL
              LIABILITY, TORT LIABILITY (INCLUDING NEGLIGENCE), OR STRICT LIABILITY), EVEN IF THE
              LIABLE PARTY KNEW OR SHOULD HAVE KNOWN THAT THOSE KINDS OF DAMAGES WERE POSSIBLE. EACH
              PARTY’S MAXIMUM CUMULATIVE LIABILITY FOR ALL OCCURRENCES UNDER OR IN CONNECTION WITH
              THIS AGREEMENT SHALL NOT EXCEED THE INJURED PARTY’S ACTUAL DIRECT DAMAGES, CAPPED AT
              THE TOTAL AMOUNT PAID BY CUSTOMER TO PROVIDER UNDER THIS AGREEMENT. THE FOREGOING
              LIMITATIONS DO NOT APPLY TO A PARTY’S OBLIGATIONS WITH RESPECT TO THIRD PARTY CLAIMS
              FOR BODILY INJURY (INCLUDING DEATH) OR DAMAGE TO TANGIBLE PERSONAL PROPERTY, OR TO
              CLAIMS BASED ON BREACH OF CONFIDENTIALITY, INTENTIONAL BREACH OF THIS AGREEMENT, OR
              WILLFUL MISCONDUCT. THE FOREGOING LIMITATIONS OF LIABILITY SHALL NOT APPLY TO THE
              EXTENT THAT THE LIABLE PARTY IS NOT PERMITTED TO DISCLAIM (OR, AS APPLICABLE, LIMIT)
              ITS LIABILITY UNDER APPLICABLE LAW.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'DURATION AND TERMINATION.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              a- Duration of Agreement. This Agreement commences on the Subscription Term start date
              set forth in the Customer Order Form and expires or terminate in accordance with the
              Customer Order Form.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              b- Termination. Either Customer or Provider may terminate this Agreement, and the
              Customer Order Form (a) as provided in the Customer Order Form, or (b) if the other
              Party becomes the subject of a petition in bankruptcy or any other proceeding relating
              to insolvency, receivership, liquidation or assignment for the benefit of creditors.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              c- Effects of Termination. Effective immediately upon expiration or termination of
              this Agreement, (i) all rights granted under this Agreement will become void, (ii)
              Customer shall cease all use of the SaaS, and (iii) neither Party will have continuing
              rights to use any Confidential Information of the other Party or to exercise any
              Intellectual Property Rights of the other Party that were licensed under this
              Agreement.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              d- Survival. Any provision of the Agreement that contemplates or governs performance
              or observance subsequent to its termination or expiration will survive the expiration
              or termination of this Agreement (or the applicable Customer Order Form) for any
              reason.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'PROPRIETARY RIGHTS.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              a- Services and Provider Content. The Services (including the SaaS) and Provider
              Content, and all Intellectual Property Rights in and to them, are and shall remain
              owned by Provider (and its licensors, as applicable) and are protected by copyright,
              trademark, patent, trade secret and other laws and treaties. Subject to the terms and
              conditions of this Agreement, Provider hereby grants Customer and Authorized Customer
              Entities a limited, personable, revocable, non-sublicensable and non-transferable
              license for their Authorized Users to access and use the functions and features of the
              SaaS during the Subscription Term solely for the Authorized Purpose(s). Any derivative
              work Customer, an Authorized Customer Entity, or any Authorized Users may create of
              any part of the SaaS or Provider Content, and all rights be therein, shall be owned
              solely by Provider. To that end, Customer hereby irrevocably transfers and conveys to
              Provider, without further consideration, all right, title and interest that Customer
              or any Authorized User may have or acquire in any such derivative work.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              b- User Content License. Customer hereby grants to Provider a free of charge
              nonexclusive, non-transferable right and license to access, use, host, copy, display,
              process, transmit, and deliver the User Content as necessary or convenient for
              Provider to comply with its obligations and exercise its rights under this Agreement.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              c- Trademarks. If Provider agrees to create, at Customer’s request, any
              Customer-branded or co-branded user interfaces through which Authorized Users will
              access the SaaS, Customer hereby grants to Provider during the Subscription Term a
              non-exclusive, worldwide, royalty-free license to use and display the Customer’s name,
              logo and other trademarks (“Customer Trademarks”) designated by Customer on such user
              interface(s). In such event, Provider will use the relevant Customer Trademarks in
              accordance with Customer’s then-current trademark usage guidelines, if any, provided
              by Customer to Provider and only for the agreed purposes. Subject to the foregoing
              license, Customer will retain all Intellectual Property Rights that it may have in and
              to the Customer Trademarks, and all use thereof by Provider shall inure to the sole
              benefit of Customer.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              d- Feedback. If Provider receives from Customer or any of its Authorized Users any
              suggestions, ideas, improvements, modifications, feedback, error identifications or
              other information related to the Services or any other Provider products, offerings or
              services (“Feedback”), Provider may use, disclose and exploit such Feedback without
              restriction and without paying any royalties or other compensation, including to
              improve the Services and to develop, market, offer, sell and provide other products
              and services.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              e- No Implied Licenses by Provider. Customer acknowledges that there are no licenses
              granted by Provider by implication under this Agreement. Provider reserves all rights
              that are not expressly granted herein. Customer acknowledges that, as between the
              Parties, Provider owns all Intellectual Property Rights and proprietary interests that
              are embodied in, or practiced by, the SaaS or other Services, with the exception of
              Intellectual Property Rights in or to Customer Data or to User Content that may be
              distributed through the SaaS.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'CONFIDENTIALITY OBLIGATIONS; PUBLICITY.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              All activities of the parties under or in relation to this Agreement are subject to
              the duty of confidentiality, and to the NDA signed between the Customer and provider
              (if any). Neither Party may use the name of the other in any published advertising or
              publicity materials without the prior written consent of the other party. However, and
              notwithstanding anything to the contrary in the NDA, Provider may include Customer’s
              name on Provider’s customer list and, subject to Customer’s prior written review and
              approval, may describe briefly, and in general terms, the nature of the services
              provided by Provider to Customer.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'GENERAL',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              a- Refund Policy. The Provider would try to ensure the Customer’s satisfaction on a
              best effort basis with their orders. Accordingly, after settlement of a certain order,
              if the Customer (in this case operator) is not pleased/satisfied with a certain item,
              then he has to provide concrete evidence and/or proof of his dissatisfaction, before
              applying for a refund on the chosen item(s). Consequently, the Provider would contact
              the relevant Customer (in this case supplier) and inform him about the refund
              application requested by the Customer (in this case operator). Once the refund
              application requested is approved by the relevant Customer (in this case supplier),
              this is when the Provider shall proceed with the refund. For the avoidance of doubt,
              prior to an order settlement the application allows the Customer to reject certain
              item(s) prior to settlement.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              b- Governing Law. The validity, construction, and interpretation of this Agreement and
              the rights and duties of the Parties shall be governed by the laws of Lebanon without
              regard to principles of conflicts of laws.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              c- Force Majeure. Notwithstanding any other provision of this Agreement, no party to
              the Agreement shall be deemed in default or breach of this Agreement or liable for any
              loss or damages or for any delay or failure in performance (except for the payment of
              money) due to any cause beyond the reasonable control of, and without fault or
              negligence by, such party or its officers, directors, employees, agents or
              contractors.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              d- Dispute Resolution. A party may, without waiving any remedy under this Agreement,
              seek from any court with jurisdiction, interim or provisional equitable relief
              necessary to protect such party’s rights or property. Any civil action seeking
              injunctive relief or otherwise related to this Agreement will be instituted and
              maintained exclusively in Lebanon. Each Party expressly waives any right to a trial by
              jury in any action or proceeding brought by or against either Party under this
              Agreement.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              e- Notice. All notices required or permitted under this Agreement will be in writing
              and sent by certified mail, return receipt requested, or by reputable oversight
              courier, or by hand delivery. The notice address for Provider and Customer shall be
              their respective addresses specified in the applicable Customer Order Form. Any notice
              sent in the manner sent forth above shall be deemed sufficiently given for all
              purposes hereunder in the case of overnight courier or hand delivery, upon delivery.
              Either party may change its notice address by giving written notice to the other party
              by the means specified in this Section.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              f- Construction. Headings. No provision of this Agreement shall be construed against
              or interpreted to the disadvantage of any Party by any court or arbitrator by reason
              of such Party having or being deemed to have structured or drafted such provision. The
              headings in this Agreement are for reference purposes only and shall not be deemed to
              have any substantive effect.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              g- Severability. If any provision of this Agreement is held by a court of competent
              jurisdiction to be contrary to law, then the Parties agree to replace it with an
              enforceable provision reflecting the intent of the original provision as nearly as
              possible in accordance with applicable law, and the remaining provisions of this
              Agreement will remain in full force and effect.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              h- Waiver. The failure of either Party at any time to require performance by the other
              Party of any provision of this Agreement shall not affect in any way the full right to
              require the performance at any subsequent time. The waiver by either Party of a breach
              of any provision of this Agreement shall not be taken or held to be a waiver of the
              provision itself. Any course of performance shall not be deemed to amend or limit any
              provision of this Agreement.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              i- Entire Agreement; Amendments. This Agreement (including all Statements of Work and
              Customer Order Forms entered under it) constitutes the entire agreement between
              Provider and Customer with respect to the subject matter hereof. There are no
              restrictions, promises, warranties, covenants, or undertakings other than those
              expressly set forth herein and therein. This Agreement supersedes all prior
              negotiations, agreements, and undertakings between the Parties with respect to such
              matter. This Agreement may be amended only by an instrument in writing executed by the
              Parties’ duly authorized representatives.
            </Typography>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              j- Enforceability. this Agreement shall enter into full force and effect upon its
              acceptance by the Customer upon its subscription to the application of the Provider.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'ACCESS AND INTERFERENCE.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              Customer agrees that it will not: Uses any robot, spider, scraper, deep link or other
              similar automated data gathering or extraction tools, program, algorithm or
              methodology to access, acquire, copy or monitor the Service or any portion of the
              Service without Provider’s express written consent, which may be withheld in
              Provider’s sole discretion; Use or attempt to use any engine, software, tool, agent,
              or other device or mechanism (including without limitation browsers, spiders, robots,
              avatars or intelligent agents) to navigate or search Provider’s application, other
              than the search engines and search agents available through the Service and other than
              generally available third party web browsers (such as Microsoft Internet Explorer);
              Post or transmit any file which contains viruses, worms, Trojan horses or any other
              contaminating or destructive features, or that otherwise may interfere with the proper
              working of Provider or the Service; or Attempt to decipher, decompile, disassemble, or
              reverse-engineer any of the software comprising or in any way making up a part of
              F.O.X..com, the F.O.X. mobile application or the Service.
            </Typography>
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
        Terms and Conditions
      </Typography>
      {termsData.map((item, i) => (
        <Box key={i} marginBottom={i < termsData.length - 1 ? 4 : 0}>
          <TermsSection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default TermsContent;
