import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TermsSection = ({ title, description }) => (
  <Box>
    <Typography
      variant="h6"
      gutterBottom
      sx={{
        fontWeight: 'bold',
      }}
    >
      {title}
    </Typography>
    {description}
  </Box>
);

TermsSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
};

const PrivacyContent = () => {
  const privacyData = [
    {
      title: 'PREFACE',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              The present General Privacy Policy (hereinafter the <b>“Privacy Policy”</b>) is that
              of Cartify Portal-FZCO (hereinafter referred to as <b>“Cartify Portal”</b>) and of all
              of its licensee(s) (the <b>“Licensee(s)”</b>). Your relationship with either or with
              all entities on this Application as defined below shall be regulated separately by the
              present Privacy Policy. In addition, Your relationship with either entity above shall
              be separate and distinct from Your possible relationship with another entity. Under no
              circumstances whatsoever, shall Cartify Portal and its Licensee(s) be deemed or held
              jointly and severally liable towards You, on any grounds whatsoever.
              <br />
              <br />
              This Privacy Policy shall govern the relationship between you, as a{' '}
              <b>
                supplier of food and/or beverage (the “Supplier”) or an operator (such as
                restaurants) (the “Operator”)
              </b>
              as the case may be visitor and user of the Application (<b>“You”, “Your”</b>) and the
              entity(ies) with whom You are dealing (hereinafter referred to by{' '}
              <b>“We”, “Our”, “Us”</b>), including without limitation regarding the use, collection
              and processing of Personal Information as defined below on the Application.
              <br />
              <br />
              This Privacy Policy describes the policies and procedures adopted by Us on the
              collection, use and disclosure of Your information on <b>Cartify</b> (the
              <b>&quot;Application&quot;</b>), as required by the applicable data protection laws
              and regulations including without limitation as applicable (1) the European Union
              General Data Protection Regulation (Regulation 2016/679 - <b>“GDPR”</b>); and (2) any
              applicable local data protection laws and regulations in the relevant territory .
              <br />
              <br />
              Thus, We have implemented a robust data protection compliance program, which includes
              the adoption of the high-standard data protection principles.
              <br />
              <br />
              We receive information about You from various sources, including: (i) if You register
              for the Application and the Services (as defined under the general Terms and
              Conditions), through your user account on the Services (your{' '}
              <b>&quot;Account&quot;</b>); and (ii) Your use of the Services generally.
              <br />
              <br />
              When You use the Services on the Application, You are consenting to this Privacy
              Policy and to the collection, recording, organization, structuring, storage,
              adaptation or alteration, retrieval, consultation, use, transfer, disclosure by
              transmission, dissemination or otherwise making available, alignment or combination,
              restriction, erasure or destruction and other uses and processing of Your Personal
              Information (as defined below), as further detailed under this Privacy Policy and in
              accordance with its terms, for the purposes of providing You with the Services on the
              Application and for the other purposes as may be defined under this Privacy Policy.
              <br />
              <br />
              No processing whatsoever of Our personal information shall intervene without our prior
              written consent and such processing shall take place in all events in accordance with
              applicable data protection legislations and regulations. We reserve as well in this
              regard all Our rights and protections as established by applicable data protection
              legislation and regulations.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'PRINCIPLES OF THE PRIVACY POLICY',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              We do not rent or sell Your Personal Information. We do not share Your Personal
              Information with any third party except for purposes of complying with the applicable
              law, providing the Services You have requested, protecting Our rights, or otherwise as
              provided in this Privacy Policy. We do not store Personal Information on our servers
              unless and only for the time required for the provision of the Services and/or for the
              on-going operation of one of Our Services.
              <br />
              <br />
              Your Personal Information is processed lawfully, fairly and in a transparent manner.
              It is further collected for specified, explicit and legitimate purposes and will not
              be further processed in a manner that is incompatible with those purposes.
              <br />
              <br />
              The Personal Information We collect is adequate, relevant and limited to what is
              necessary in relation to the purposes for which they are processed. Your Personal
              Information is accurate and, where necessary, kept up to date by Us in accordance with
              the terms herein. Subject to the terms of this Privacy Policy, Your Personal
              Information is processed in a manner that ensures appropriate security of the personal
              data, including protection against unauthorized or unlawful processing and against
              accidental loss, destruction or damage.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'SCOPE OF THIS PRIVACY POLICY.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="div" color="textSecondary" sx={{ textAlign: 'justify' }}>
              This Privacy Policy covers the treatment of personally identifiable information (
              <b>&quot;Personal Information&quot;</b>) gathered while You are using or accessing the
              Services on the Application or through some other method. We may further share
              Personal Information with Our affiliates (such as Marketplace sellers) and/or with the
              Suppliers and/or with the Operators, as the case may be, for the purposes of providing
              You with the Services in accordance with the safeguards foreseen in this Privacy
              Policy.
              <br />
              <br />
              However, this Privacy Policy does not apply to the practices of third parties that we
              do not own or control, including but not limited to any third-party websites, services
              and applications (<b>&quot;Third-Party Services&quot;</b>) that You elect to access
              through the Service or to individuals and entities that we do not manage or employ. We
              cannot take responsibility for the content or privacy policies of those Third-Party
              Services. We encourage You to carefully review the privacy policies of any Third-Party
              Services You access. If You decided to share any of Your Personal Information or
              content with any third party, We shall not be liable or responsible whatsoever for any
              damages, losses or misuse of data resulted directly or indirectly from such act.
              <br />
              <br />
              In addition to the above, You hereby acknowledge and agree that We take no
              responsibility whatsoever for any processing by either the Supplier or by the
              Operator, as the case may be, of Your Personal Information including without
              limitation for any non-compliant processing of Your Personal Information.
              <br />
              <br />
              We process the below described Personal Information, in relation to You as Supplier or
              an Operator, as the case may be, for as long as necessary for the provision of the
              Services and for the sole purposes of such provision of the Services.
              <br />
              <br />
              The Supplier or the Operator, as the case may be, shall comply with their obligations
              as defined under applicable data protection laws and regulations towards respectively
              the Operator or the Supplier, as the case may be, including without limitation
              provision of the required information in relation to the processing of Personal
              Information, granting the relevant party access to their Personal Information,
              rectifying the inaccurate Personal Information and completing the incomplete Personal
              Information and/or erasing the Personal Information.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'INFORMATION SECURITY',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              We shall ensure that the appropriate technical and organizational measures ensuring
              the security of the Personal Information have been taken such as pseudonymization and
              encryption of Personal Information, the ability to ensure the ongoing confidentiality,
              integrity, availability and resilience of processing systems and services; and the
              ability to restore the availability and access to Personal Information in a timely
              manner in the event of a physical or technical incident. In this respect, we work to
              protect the security of Your information during transmission by using Secure Sockets
              Layer (SSL) software, which encrypts information you input.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'WHAT PERSONAL DATA DO WE COLLECT?',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              The information We gather enables us to personalize, improve and continue to operate
              and provide You the Services. In connection with certain aspects of the Services, We
              may request, collect and/or display some of Your Personal Information. We collect the
              following types of information from Our users:
            </Typography>
            <Box gutterBottom sx={{ mt: 2, mb: 2 }}>
              <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                o Mobile Information
              </Typography>
            </Box>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              When You download or use the Application, We may ask for Your consent to share with Us
              Your location and information about Your mobile device. We may use this information to
              provide You with location-based services, such as advertising, search results, and
              other personalized content. Most mobile devices allow You to turn off location
              services. Most likely, these controls are located in the device settings menu. For
              information about specific devices, and how to disable Your device location services,
              We recommend You contact Your mobile service carrier or Your device manufacturer.
            </Typography>

            <Box gutterBottom sx={{ mt: 2, mb: 2 }}>
              <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                o Account Information.
              </Typography>
            </Box>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              We collect Personal Information, such as Your email address, name, address, phone
              number, birth date, when You create an Account to use the Services on the Application.
              You acknowledge that this information may be personal to You, and by creating an
              Account on the Services and providing Personal Information to Us, You allow Us to
              identify You.
            </Typography>
            <br />
            <Typography
              component="p"
              color="textSecondary"
              sx={{ textAlign: 'justify', fontStyle: 'italic' }}
            >
              Important Notice: When submitting any personal data, including multimedia contents
              (photographs, videos…) please ensure it is accurate and do not transmit any content
              which is not expressly requested through a questionnaire or any other collection
              method (such as commercial information, advertising, personal creations, ideas, or
              concepts).
            </Typography>

            <Box gutterBottom sx={{ mt: 2, mb: 2 }}>
              <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                o User Content.
              </Typography>
            </Box>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              Some features of the Services allow You to provide content to the Services, such as
              written comments, description of products and/or services, photographs and images. We
              may retain all content submitted by You to the Services indefinitely, even after You
              terminate your Account, unless the content contains Personal Information, in which
              case we can retain the content for as long as necessary for the provision of the
              Services or You can request that the content be provided to You and/or be deleted.
            </Typography>

            <Box gutterBottom sx={{ mt: 2, mb: 2 }}>
              <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                o IP Address information and other information collected automatically .
              </Typography>
            </Box>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              Some additional information is collected indirectly about You while You are using the
              Application. This information is technically needed for the proper functioning of the
              Application, to improve Your experience or in order for Us to perform analytics that
              will enable Us to serve You better by personalizing Our Services.
              <br />
              <br />
              <ul>
                Such information consists without limitation of the following:
                <li>
                  Technical information, such as IP address and cookies, linked to Your utilization
                  of the Application, for logging purposes and identification of Your country/city
                  via geolocation, noting that the Application automatically reports IP addresses
                  each time you utilize the Application;
                </li>
                <li>
                  Information relative to Your online profile and Application usage data such as
                  behavioral analysis via cookies and similar technologies, Your navigation trail of
                  the Application, registration of products in the shopping cart, Your purchase
                  history which We sometimes aggregate with similar information from other Suppliers
                  and/or Operators to create features like Top Sellers;{' '}
                </li>
                <li>
                  During some of Your visits to the Application, We may use analytics software tools
                  to measure and collect session information, including page response times, length
                  of visits to certain pages, page interaction information (such as scrolling,
                  clicks), and methods used to browse away from the page. We may also collect
                  technical information to help Us identify Your device for fraud prevention and
                  diagnostic purposes.{' '}
                </li>
                <li>
                  Personal data collected via media platforms and social networks (such as when You
                  follow our pages on those social networks, Personal Information that You have
                  designated as public and that can be publicly viewed on your social network
                  profile).
                </li>
              </ul>
              <br />
              IP addresses may be used for various purposes, including inter alia (i) to diagnose or
              service technology problems reported by Our users or engineers that are associated
              with the IP addresses controlled by a specific web company; (ii) to tailor advertising
              based on geographic area or other information derived from Your IP address whereby
              aggregate information derived from IP addresses may also be reported to advertisers;
              and (iii) to estimate the total number of users visiting the Services from specific
              geographical regions.
            </Typography>

            <Box gutterBottom sx={{ mt: 2, mb: 2 }}>
              <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                o Personal Information.
              </Typography>
            </Box>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              For the purposes of providing You with the Services on the Application, the Personal
              Information We collect and process include without limitation Your name, Your company
              legal name, address, email address, identification number, phone number, title and
              credit card information.
              <br />
              <br />
              You provide most such information when You search, buy, post, participate in a contest
              or questionnaire, or communicate with customer service. For example, You provide
              information when You search for a product; place an order through the Application.
              Accordingly, You could supply Us with information such as Your name, address, and
              phone numbers; credit card information; people to whom purchases have been delivered
              or received, including addresses and phone number.
            </Typography>

            <Box gutterBottom sx={{ mt: 2, mb: 2 }}>
              <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                o E-mail Communications.
              </Typography>
            </Box>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              In the future, We may implement email confirmation in order to help Us make emails &
              services more useful for businesses subscribed on the Application.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'INFORMATION DISCLOSURE.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              When You access or use the Services, We collect, use, share, and otherwise process
              Your Personal Information as described in this Privacy Policy. When You use the
              Services on the Application, You are consenting to the collection, recording,
              organization, structuring, storage, adaptation or alteration, retrieval, consultation,
              use, transfer, disclosure by transmission, dissemination or otherwise making
              available, alignment or combination, restriction, erasure or destruction and other
              uses and processing of Your Personal Information, as further detailed under this
              Privacy Policy and in accordance with its terms. We process Your Personal Information
              when necessary <i>inter alia</i>:
              <ul>
                <li>
                  For Us to provide the Services to You, including for the purposes of receiving
                  from You as an Operator the purchase orders, finalizing all details and
                  confirmations in relation to such orders between You and the Supplier(s) and for
                  the purposes of conveying those received orders to the Supplier(s).
                  <br />
                  <br />
                  As to Supplier(s), We process Your Personal Information when necessary inter alia
                  to provide You with the Services on the Application, and in order to provide the
                  services as described above to the Operator(s) and to You.
                </li>
              </ul>
              <br />
              Processing of Your Personal Information by Us, for both the Supplier(s) and
              Operator(s), shall be deemed as well lawful on the following lawful bases:
              <ul>
                <li>For compliance with a legal obligation;</li>
                <li>To protect the vital interests of Yourselves or others;</li>
                <li>In the public interest;</li>
                <li>
                  For the purposes of the legitimate interests pursued by Us or by a third party,
                  except where such interests are overridden by Your interests or fundamental rights
                  and freedoms which require protection of Your Personal Information.
                </li>
              </ul>
              <br />
              Some of Your activity on and through the Services is public by default. This may
              include, but is not limited to, content You have posted publicly on the Application or
              otherwise through the Services, and within Your public profile page. Thus, such
              content becomes publicly available and can be collected and used by others.
              <br />
              <br />
              We may use Your email address without further consent for non-marketing or
              administrative purposes.
              <br />
              <br />
              We shall not disclose (or otherwise process) Personal Information about You to other
              individuals or entities without your consent, except as necessary to provide Services
              requested by You including Our Services provided through the Application and Our
              services of finalizing all details and confirmations between the Operator(s) and the
              Supplier(s) in relation to the purchase orders and our Services of conveying those
              received purchase orders to the Supplier(s), and the preceding shall apply
              notwithstanding any clause to the contrary contained herein.
              <br />
              <br />
              Your consent on the disclosure by Us is not as well required in the following
              circumstances:
              <ul>
                <li>
                  We may be required to disclose Personal Information and/or non-personally
                  identifiable information to comply with applicable laws and regulations,
                  subpoenas, warrants, court orders or legal process or to establish or exercise Our
                  legal rights or defend against legal claims.
                </li>
                <li>
                  It may be necessary to share Personal Information in order to investigate,
                  prevent, or take action regarding suspected or actual illegal activities,
                  including as permitted or required by laws or regulations.
                </li>
                <li>
                  We reserve the right to disclose Personal Information and/or
                  non-personally-identifiable information that We deem, in good faith, is
                  appropriate or necessary to assist government enforcement agencies, or to protect
                  the security or integrity of Our Application, and to protect Our rights, and
                  property.
                </li>
                <li>
                  We will transfer information about You if We are acquired by or merged with
                  another company. In such instance, We (i) will notify You by email or by putting a
                  prominent notice on the Application before information about You is transferred
                  and becomes subject to a different privacy policy; (ii) reserve the right, in any
                  of these circumstances, to transfer or assign the information already collected
                  from Our users as part of such merger, acquisition, sale, or other change of
                  control.
                </li>
              </ul>
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'INFORMATION SHARING.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              <ul>
                <li>
                  We may contract with various third parties who help Us provide and maintain the
                  Application or the Services (Our “Agents”). In this respect, We may need to share
                  Personal Information with Our Agents in order for Us to provide Services to You.
                  Unless We inform You otherwise, Our Agents do not have any right to use Personal
                  Information or other information and We solely share with them what is necessary
                  to assist Us. Where such Agents are appointed, We shall bind them to contractual
                  provisions providing data protection obligations identical to those contained
                  herein including providing sufficient guarantees to implement appropriate
                  technical and organizational security measures. You hereby consent to Our sharing
                  of Personal Information with Our Agents for the above purposes. We will use
                  commercially reasonable efforts to prevent such third parties from disclosing Your
                  Personal Information.{' '}
                </li>
                <br />
                <li>
                  Subject to the other terms of this Privacy Policy, We limit access to Personal
                  Information about You to Our personnel on a strict need-to-know basis for purposes
                  of providing Services to You and for them to properly execute their scope of work.
                  You hereby consent to Our sharing of Personal Information with Our employees for
                  the above purposes.
                </li>
                <br />
                <li>
                  We may share Your information in connection with, or during negotiations of, any
                  merger, sale of company assets, financing or acquisition of all or a portion of
                  Our business by another company. We may also share Your information between and
                  among Cartify Portal and its Licensee(s), and their current and future parents,
                  affiliates, subsidiaries, and other companies under common control and ownership.
                </li>
              </ul>
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'PROMOTIONAL OFFERS',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              Sometimes we send offers to selected groups of Suppliers and/or Operators on behalf of
              other businesses. When we do this, we do not give that business your name and address.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'MODALITIES FOR THE EXERCISE OF THE RIGHTS',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              <b>
                For the avoidance of doubt, each of the Supplier(s) and of the Operator(s) shall be
                solely responsible for their own processing of Personal Information activities
                (including responsibility towards the data subject with regard to the below rights),
                without any responsibility whatsoever on Us. In this regard, as applicable, each of
                the Supplier(s) and Operator(s) shall comply with their processing obligations as
                established by applicable laws.
                <br />
                <br />
                As a data subject, Your rights include the following:
              </b>
              <ul>
                <li>
                  <b>A right of access and information:</b> You have the right to be informed in a
                  concise, transparent, intelligible and easily accessible form of the way in which
                  Your Personal Information is processed. You also have the right to obtain (i)
                  confirmation as to whether or not Personal Information concerning You are being
                  processed, and, where that is the case, the purposes of such processing, the
                  object of the processing and its nature, the categories of Personal Information
                  concerned, the categories of recipients of the data or of those who have access to
                  the data, and the period for which the data is stored. Typically, Your Personal
                  Information will be stored as long as Your Account exists on the Application, and
                  as long as necessary for the purposes of providing You with the Services; and (ii)
                  to access such Personal Information and obtain a copy thereof.
                </li>
                <br />
                <li>
                  <b>A right to rectification:</b> You have the right to obtain the rectification of
                  Your Personal Information in all events in accordance with the timeframes as set
                  by the applicable laws. You also have the right to have incomplete Personal
                  Information completed. Accordingly, You agree that it is Your responsibility to
                  notify Us (or the Supplier(s) or the Operator(s), as the case may be, in
                  accordance with the below at its email address) of this fact at the below
                  indicated mailing or postal address and to supply Us (or the Supplier (s) or the
                  Operator(s), as the case may be, in accordance with the below at its email
                  address) with the accurate or complete information to enable Us (or the Supplier
                  (s) or the Operator(s), as the case may be) to address Your concerns accordingly.
                  <br />
                  <br />
                  We shall rectify and complete the Personal Information as described above, as long
                  as We are still processing the Personal Information for the purposes of the
                  Services.
                </li>
                <br />
                <li>
                  <b>A right to erasure:</b> You have the right to obtain the erasure of Your
                  Personal Information including whereby, in accordance with the applicable laws,
                  the Personal Information are no longer necessary in relation to the purposes for
                  which it was collected or otherwise processed, You withdraw Your consent on which
                  the processing is based and where there is no other legal ground for the
                  processing; You object to the processing in accordance with the applicable laws
                  and there are no overriding legitimate grounds for the processing; Your Personal
                  Information has been unlawfully processed; the Personal Information have to be
                  erased for compliance with a legal obligation. Where applicable, in accordance
                  with the relevant jurisdiction, You shall have the right to obtain the erasure of
                  Your Personal Information whereby the information becomes obsolete or is
                  incompatible with the purposes of the processing or is prohibited to be processed,
                  collected, used, stored or transferred. However, the right to erasure is not an
                  absolute right and We may have legal or legitimate grounds for keeping such
                  Personal Information in accordance with the applicable Laws.
                  <br />
                  <br />
                  We shall erase the Personal Information as described in the first paragraph above,
                  as long as We are still processing the Personal Information for the purposes of
                  the Services.
                </li>
                <br />
                <li>
                  <b>A right to object to processing:</b> You may not exercise Your right to object
                  whereby We are compelled by law to collect the Personal Information and whereby
                  You have consented to the processing of Your Personal Information.
                  <br />
                  <br />
                  We shall comply with Our obligations in relation to the objection to processing,
                  as long as We are still processing the Personal Information for the purposes of
                  the Services.
                </li>
                <br />
                <li>
                  <b>A right to withdraw Your consent:</b> In accordance with the applicable laws,
                  You shall have the right to withdraw Your consent to processing at any time. The
                  withdrawal of consent shall not affect the lawfulness of processing based on Your
                  consent before its withdrawal.
                </li>
              </ul>
              To exercise the above rights please contact Us via the email provided below and
              communicate the following information: name, surname, e-mail or any other information
              necessary to confirm Your identity in order for Us to process the request (such as
              your postal address). You can delete Your Account at any time. Please note in this
              respect that (i) Your identification and contact information may however remain in Our
              records for a period of 30 days; (ii) any association between Your Account and
              information We store will no longer be accessible through Your Account; and (iii) any
              public activity on your Account prior to deletion may remain stored on Our servers and
              may remain accessible to the public unless removed pursuant to Your request.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: "CHILDREN'S PRIVACY.",
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              Our Services do not address anyone under the age of 18. We do not knowingly collect
              personally identifiable information from children. If You are a parent or guardian and
              You are aware that Your children has provided Us with Personal Information, please
              contact Us. If We discover that a child has provided Us with Personal Information, We
              will delete such information from Our servers immediately.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'DATA SECURITY.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              Your Account information is password-protected and thus You need to prevent
              unauthorized access to Your Account and Personal Information by selecting and
              protecting Your password appropriately and limiting access to Your device by signing
              off after You have finished accessing Your Account. We use commercially reasonable
              physical, managerial, and technical safeguards, such as commercially available
              encryption protocols, to preserve the integrity and security of Your Personal
              Information. Once We receive Your transmission of information, We make commercially
              reasonable efforts to ensure the security of Our systems.
              <br />
              <br />
              However, please note that this is not a guarantee that such information may not be
              accessed, disclosed, altered, or destroyed by violation of any of our physical,
              technical, or managerial safeguards for reasons outside of Our control.
              <br />
              <br />
              When a Personal Information breach is likely to result in a high risk to Your rights
              and freedoms, We shall communicate to You the personal data breach without undue
              delay.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'MODIFICATIONS AND UPDATES TO THIS PRIVACY POLICY.',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              This Privacy Policy may be revised at any time, without notice, and such amendments
              shall be applicable as from the day they are published on the Application. Thus, We
              invite You to regularly consult the present page to stay aware of any such amendments.
              Your continued use of the Application and of the Services constitute Your agreement
              and consent to the so updated Privacy Policy.
              <br />
              <br />
              For revisions to this Privacy Policy that may be materially less restrictive on Our
              use or disclosure of the Personal Information You have already provided to Us, We will
              attempt to obtain Your consent before implementing such revisions with respect to such
              information.
              <br />
              <br />
              In any event Our Privacy Policy will remain subject to the terms of the applicable
              data privacy laws and regulations.
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: 'HOW TO CONTACT US?',
      description: (
        <Box>
          <Box gutterBottom sx={{ mb: 1 }}>
            <Typography component="p" color="textSecondary" sx={{ textAlign: 'justify' }}>
              If You have any questions You wish to ask Us, or requests to address to Us, or
              concerns regarding the Privacy Policy for the Services, please contact Us at{' '}
              <a href="mailto:support@cartify.org" target="_blank" rel="noreferrer">
                support@cartify.org
              </a>{' '}
              We will exert our commercially reasonable efforts to properly and without undue delay
              respond to Your inquires, requests and resolve Your concerns.
              <br />
              <br />
              We may ask You to provide additional information for identity verification purposes,
              or to verify that You are in possession of an applicable email account.
            </Typography>
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
        Privacy Policy
      </Typography>
      {privacyData.map((item, i) => (
        <Box key={i} marginBottom={i < privacyData.length - 1 ? 4 : 0}>
          <TermsSection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default PrivacyContent;
