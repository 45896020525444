import { useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import TermsAndConditions from 'src/pages/TermsAndConditions';
import ComingSoonPage from 'src/pages/coming-soon';
import ContactPage from 'src/pages/contact-us';
import HomePage from 'src/pages/home';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },
    {
      path: '/privacy-policy',
      element: (
        <MainLayout>
          <PrivacyPolicy />
        </MainLayout>
      ),
    },
    {
      path: '/terms-and-conditions',
      element: (
        <MainLayout>
          <TermsAndConditions />
        </MainLayout>
      ),
    },
    {
      path: '/contact-us',
      element: (
        <MainLayout>
          <ContactPage />
        </MainLayout>
      ),
    },
    {
      path: '/coming-soon',
      element: (
        <MainLayout>
          <ComingSoonPage />
        </MainLayout>
      ),
    },
  ]);
}
